import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  Card,
  TextMedium,
  ButtonTransparent,
  FormColumn,
} from '../../components';
import * as Icons from '@mui/icons-material';

const CommandButtons = (props) => {
  const {
    dimensions,
    navigatePage,
  } = props;

  const [cardWidth, setCardWidth] = React.useState(150);
  const [iconSize, setIconSize] = React.useState(100);
  const [fontSize, setFontSize] = React.useState(15);

  React.useEffect(() => {
    if (dimensions.width < 390) {
      setCardWidth(dimensions.width / 2 - 50);
      setIconSize(50);
      setFontSize(10);
    } else {
      setCardWidth(150);
      setIconSize(100);
      setFontSize(15);
    }
  }, [dimensions]);

  return (
    <FormRow flexWrap='wrap' justifyContent='center'>
      <FormColumn padding={5}>
        <ButtonTransparent
          onPress={() => navigatePage('/FaceCollector')}
        >
          <Card
            borderColor='#456091'
            borderWidth={4}
          >
            <FormColumn height={cardWidth} width={cardWidth} alignItems='center' justifyContent={'center'}>
              <Icons.AddReaction style={{ color: '#456091', fontSize: iconSize }} />
              <TextMedium align='center' fontSize={fontSize} color='#456091'>{`${getDic("capturar")} ${getDic("rosto")}`}</TextMedium>
            </FormColumn>
          </Card>
        </ButtonTransparent>
      </FormColumn>
      <FormColumn padding={5}>
        <ButtonTransparent
          onPress={() => navigatePage('/FaceScan')}
        >
          <Card
            borderColor='#456091'
            borderWidth={4}
          >
            <FormColumn height={cardWidth} width={cardWidth} alignItems='center' justifyContent={'center'}>
              <Icons.RecordVoiceOver style={{ color: '#456091', fontSize: iconSize }} />
              <TextMedium align='center' fontSize={fontSize} color='#456091'>{`${getDic("reconhecimento-facial")}`}</TextMedium>
            </FormColumn>
          </Card>
        </ButtonTransparent>
      </FormColumn>
      <FormColumn padding={5}>
        <ButtonTransparent
          onPress={() => navigatePage('/DeleteEventData')}
        >
          <Card
            borderColor='#456091'
            borderWidth={4}
          >
            <FormColumn height={cardWidth} width={cardWidth} alignItems='center' justifyContent={'center'}>
              <Icons.DeleteForever style={{ color: '#456091', fontSize: iconSize }} />
              <TextMedium align='center' fontSize={fontSize} color='#456091'>{`${getDic("deletar")} ${getDic("dados")}`}</TextMedium>
            </FormColumn>
          </Card>
        </ButtonTransparent>
      </FormColumn>
      {/* <FormColumn padding={5}>
        <ButtonTransparent
          onPress={() => navigatePage('/ExpressionRecognition')}
        >
          <Card
            borderColor='#456091'
            borderWidth={4}
          >
            <FormColumn height={cardWidth} width={cardWidth} alignItems='center' justifyContent={'center'}>
              <Icons.EmojiEmotions style={{ color: '#456091', fontSize: iconSize }} />
              <TextMedium align='center' fontSize={fontSize} color='#456091'>{`${getDic("reconhecimento-expressao-facial")}`}</TextMedium>
            </FormColumn>
          </Card>
        </ButtonTransparent>
      </FormColumn>
      <FormColumn padding={5}>
        <ButtonTransparent
          onPress={() => navigatePage('/ExpressionReport')}
        >
          <Card
            borderColor='#456091'
            borderWidth={4}
          >
            <FormColumn height={cardWidth} width={cardWidth} alignItems='center' justifyContent={'center'}>
              <Icons.RecentActors style={{ color: '#456091', fontSize: iconSize }} />
              <TextMedium align='center' fontSize={fontSize} color='#456091'>{`${getDic("relatorio")} ${getDic("reconhecimento-expressao-facial")}`}</TextMedium>
            </FormColumn>
          </Card>
        </ButtonTransparent>
      </FormColumn> */}
    </FormRow>
  )
};

export default CommandButtons;
