import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  InputField,
  SelectField,
  Button,
  TextSmall,
} from '../../components';
import * as Icons from '@mui/icons-material';

const PreForm = (props) => {
  const {
    dimensions,
    faceScanControl,
    setFaceScanControl,
    tryOpenCamera,
  } = props;

  const width = dimensions.width < 640 ? dimensions.width - 40 : 600;

  return (
    <FormRow justifyContent='center'>
      <FormColumn width={width} padding={10}>
        <FormRow>
          <InputField
            title={`ID ${getDic("evento")}`}
            value={faceScanControl.eventId}
            onChange={v => setFaceScanControl({ ...faceScanControl, eventId: v })}
          />
        </FormRow>
        <FormRow>
          <SelectField
            title={`${getDic("sistema")}`}
            value={faceScanControl.system}
            onChange={v => setFaceScanControl({ ...faceScanControl, system: v })}
            menuItens={[
              { value: '', text: getDic("selecione") },
              { value: 'inteegra_eventos', text: 'Inteegra Eventos' },
              { value: 'mice', text: 'Mice' },
              { value: 'mice_sant', text: 'Mice Sant' },
            ]}
          />
        </FormRow>
        <FormRow>
          <InputField
            title={`ID ${getDic("participante")}`}
            value={faceScanControl.guestId}
            onChange={v => setFaceScanControl({ ...faceScanControl, guestId: v })}
          />
        </FormRow>
        <FormRow paddingTop={20} justifyContent='center'>
          <Button
            width={width - 20}
            onPress={() => tryOpenCamera()}
          >
            <FormRow justifyContent='center'>
              <FormColumn justifyContent='center' paddingRight={5}>
                <TextSmall color='white' fontWeight='bold'>{`${getDic("abrir")} ${getDic("camera")}`}</TextSmall>
              </FormColumn>
              <Icons.CameraAlt style={{ color: 'white', fontSize: 15 }} />
            </FormRow>
          </Button>
        </FormRow>
      </FormColumn>
    </FormRow>
  )
};

export default PreForm;
