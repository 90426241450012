import * as faceapi from 'face-api.js';
import axios from 'axios';
import FaceDetectionModel from '../models/FaceDetectionModel';
import apiService from './apiService';
import jsonService from './jsonService';

export default {
  async loadModels() {
    await Promise.all([
      faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
      faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
      faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
    ]);
    return true;
  },

  async loadModelsExpression() {
    await Promise.all([
      faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
      faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
      faceapi.nets.faceExpressionNet.loadFromUri("/models"),
      faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
    ]);
    return true;
  },

  async loadDetections(detections = [FaceDetectionModel()]) {
    return Promise.all(
      detections.map(async (detection) => {
        let _descriptions = jsonService.parse(detection.detection);
        return new faceapi.LabeledFaceDescriptors(detection.guestId, _descriptions);
      })
    );
  },

  async getFaceDetectionFromBase64Image(base64 = '') {
    try {
      const _img = await faceapi.fetchImage(base64);
      const _detections = await faceapi
        .detectSingleFace(_img)
        .withFaceLandmarks()
        .withFaceDescriptor();
      return _detections;
    } catch (error) {
      console.log('Error getFaceDetectionFromBase64Image', error);
      throw error;
    }
  },

  async saveFaceDetection(detection = FaceDetectionModel()) {
    try {
      let _request = {
        url: `${apiService.getUrl().faceRecognition}faceDetection/createFaceDetection`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          eventId: detection.eventId,
          system: detection.system,
          guestId: detection.guestId,
          detection: detection.detection,
        }
      }

      if (detection.id) {
        _request = {
          url: `${apiService.getUrl().faceRecognition}faceDetection/updateFaceDetection`,
          method: "PUT",
          headers: {
            "Content-Type": "application/json"
          },
          data: {
            id: detection.id,
            eventId: detection.eventId,
            system: detection.system,
            guestId: detection.guestId,
            detection: detection.detection,
          }
        }
      }

      const _ret = await axios(_request);

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return FaceDetectionModel(_ret.data.data);
    } catch (error) {
      console.log('Error saveFaceDetection', error);
      throw error;
    }
  },

  async deleteFaceDetectionsByEvent(eventId = '', system = '') {
    try {
      let _token = await apiService.getUserToken();

      let _request = {
        url: `${apiService.getUrl().faceRecognition}faceDetection/deleteFaceDetectionsByEvent`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `BEARER ${_token}`,
        },
        data: {
          eventId: eventId,
          system: system,
        }
      }
      const _ret = await axios(_request);

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      return;
    } catch (error) {
      console.log('Error saveFaceDetection', error);
      throw error;
    }
  },

  async getFaceDetections(
    eventId = '',
    system = '',
    searchString = '',
    pagination = {
      limit: 1,
      offset: 0,
      hasMore: true,
    },
    orderBy = 'guest_id',
    order = 'ASC',
  ) {
    try {
      let _return = {
        data: [],
        count: 0,
        pagination: pagination
      }

      const _ret = await axios({
        url: `${apiService.getUrl().faceRecognition}faceDetection/getFaceDetections?eventId=${eventId}&system=${system}&offset=${pagination.offset}&limit=${pagination.limit}&search=${searchString}&orderBy=${orderBy}&order=${order}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      });

      if (!_ret.data.success) {
        throw new Error(_ret.data.message);
      }

      _ret.data?.data?.data?.forEach(item => {
        _return.data.push(FaceDetectionModel({
          id: item.id,
          eventId: item.eventId,
          system: item.system,
          guestId: item.guestId,
          detection: item.detection,
        }));
      });

      _return.count = _ret.data?.data?.count;

      _return.pagination = {
        limit: pagination.limit,
        offset: pagination.limit + pagination.offset,
        hasMore: _return.data.length < pagination.limit ? false : true
      };
      return _return;
    } catch (error) {
      console.log('Error getFaceDetections', error);
      throw error;
    }
  }
}
